import React, { useEffect, useState } from "react";
import "./NewFooter.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { BsFacebook, BsPinterest, BsTwitter, BsTwitterX } from "react-icons/bs";
import NewsLetter from "../NewsLetter/NewsLetter";
import { useDispatch, useSelector } from "react-redux";
import { getStaticContent } from "../StateSlices/staticContentSlice";

const NewFooter = ({ content }) => {
  // const { staticContentInfo } = useSelector((state) => state.staticContent);
  const [spinner, setSpinner] = useState(false);

  const [consent, setConsent] = useState(false)
  
  useEffect(()=>{
    setTimeout(() => {
      setConsent(true)
    }, 500);
  },[])

  // const [loading, setLoading] = useState(false);
  // const [data, setData] = useState(null);
  // console.log("From Footer Content ", content);
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   setLoading(true);
  //   dispatch(
  //     getStaticContent({
  //       values: { section: "footer" },
  //     })
  //   );
  // }, []);

  // useEffect(() => {
  //   if (staticContentInfo) {
  //     setData(staticContentInfo);
  //     console.log("k2l ", data);
  //     setLoading(false);
  //   }
  // }, [staticContentInfo]);
  const toastOption = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  return (
    <>
      <footer>
        <div class="row primary" style={{ margin: "0 auto" }}>
          {content &&
            content.length > 0 &&
            content.map((col, ind) => (
              <>
                {ind === 0 && (
                  <div class="column links quick-links" key={ind}>
                    <h3>{col.name}</h3>
                    <ul>
                      {col.links &&
                        col.links.map((link, ind) => (
                          <li>
                            <Link to={"/" + link.link}>{link.display}</Link>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}

                {ind === 1 && (
                  <div class="column links" style={{ marginTop: 0 }}>
                    <h3>{col.name}</h3>
                    <ul>
                      {col.links.map((link, ind) => (
                        <li>
                          <i
                            class={link.icon}
                            style={{ marginRight: "1rem" }}
                          ></i>
                          <span>{link.display}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {ind === 2 && (
                  <div class="column subscribe">
                    <h3>{col.name}</h3>
                    <NewsLetter
                      toast={toast}
                      setSpinner={setSpinner}
                      toastOption={toastOption}
                    />
                    <div class="social">
                      {col.links.map((link, ind) => (
                        <a href={link.link} target="_blank">
                          {ind === 0 && <BsFacebook size={"1em"} />}
                          {ind === 1 && <BsTwitterX size={".9em"} />}
                          {ind === 2 && <BsPinterest size={"1em"} />}
                        </a>
                      ))}
                    </div>
                  </div>
                )}
              </>
            ))}
        </div>
        <div class="row copyright">
          {/* <p>Copyright  2021 Coding Artist | All Rights Reserved</p> */}
          <p>
            &copy; 2023 Closest LLC |{" "}
            <Link
              to="/privacy-policy"
              style={{ color: "", textDecoration: "none" }}
              className="hover-link"
            >
              Privacy Policy
            </Link>{" "}
            |{" "}
            <Link
              className="hover-link"
              to="/terms-conditions"
              style={{ color: "", textDecoration: "none" }}
            >
              Terms & Conditions
            </Link>
            |{" "}
            <Link
              className="hover-link"
              to="/cookie-policy"
              style={{ color: "", textDecoration: "none" }}
            >
              Cookie Policy
            </Link>
            
            {consent ? (<>|{" "}<Link
              className="termly-display-preferences"
              onClick={(e) => {
                e.preventDefault();
                // Your custom behavior here
                console.log("Consent Preferences clicked");
              }}
            >
              Consent Preferences
            </Link></>):""}
            |{" "}
            <Link
              className="hover-link"
              to="/disclaimer"
              style={{ color: "", textDecoration: "none" }}
            >
              Disclaimer
            </Link>
            |{" "}
            <Link
              className="hover-link"
              to="/acceptable-use-policy"
              style={{ color: "", textDecoration: "none" }}
            >
              Acceptable Use Policy
            </Link>
            {/* <a href="#" class="termly-display-preferences">Consent Preferences</a> */}
            | All Rights Reserved.
          </p>
        </div>
      </footer>
    </>
  );
};

export default NewFooter;
