import React, { useEffect, useRef, useState } from "react";
import "./MyCloset.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import swal from "sweetalert";

// import { getInfo,reset } from "../components/StateSlices/getuserSlice"
import {
  getclosetInfo,
  resetcloset,
} from "../components/StateSlices/getClosetSlice";
import {
  deleteItem,
  resetDelete,
} from "../components/StateSlices/deleteitemSlice";
import InfoCard from "../components/InfoCard/InfoCard";
import AdminMenu from "../components/AdminMenu/AdminMenu";
import ClosetItem from "../components/ClosetItem/ClosetItem";
import Spinner from "../components/Spinner/Spinner";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import OtherClosetItem from "../components/OtherClosetItem/OtherClosetItem";
import Pagination from "../components/Pagination/Pagination";
import { Helmet } from "react-helmet";
import MyClosetBanner from "../components/MyClosetNew/MyClosetBanner";
import {
  getuserBannerInfo,
  reset,
} from "../components/StateSlices/getUserBannerInfo";
import ScrollAnimation from "react-animate-on-scroll";
import ShimmerCloset from "../shimmer/ShimmerCloset";

const Closet = () => {
  const llocation = useLocation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { closetStatus, closetInfo, closetError, closetProductCount } =
    useSelector((state) => state.closetInfo);
  const [spinner, setSpinner] = useState(false);
  const { deleteStatus, deleteInfo, deleteError } = useSelector(
    (state) => state.delete
  );
  const { userInfo } = useSelector((state) => state.user);
  const { userBannerInfo } = useSelector((state) => state.userbannerinfo);
  const [pageNumber, setPageNumber] = useState(
    llocation.search.slice(-1)
      ? llocation.search.slice(llocation.search.indexOf("=") + 1) - 1
      : 0
  );

  const [closetStateInfo, setClosetStateInfo] = useState(null);
  const [closetProductsCount, setClosetProductsCount] = useState(0);
  const [bannerSpinner, setBannerSpinner] = useState(false);
  const [bannerInfo, setBannerInfo] = useState(null);

  const toastOption = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  useEffect(() => {
    setSpinner(true);
  }, []);

  // useEffect(() => {
  //   if(llocation && llocation.state.id && !userBannerInfo){
  //     const token = localStorage.getItem("clttoken")
  //     if(token){
  //       setBannerSpinner(true)
  //       dispatch(getuserBannerInfo({id: llocation.state.id,  token: token}))
  //     }
  //   }
  // }, [llocation])

  useEffect(() => {
    if (userBannerInfo) {
      setBannerSpinner(false);
      setBannerInfo(userBannerInfo);

      dispatch(reset());
    }
  }, [userBannerInfo]);

  useEffect(() => {
    if (closetInfo) {
      setSpinner(false);
      // console.log(closetInfo);
      setClosetStateInfo(closetInfo);
      setClosetProductsCount(closetProductCount);
      dispatch(resetcloset());
    }
  }, [closetInfo]);

  const isInitialMount = useRef(true); // Track initial mount
  const isFirstPageZeroRender = useRef(true); // Tracks if page 0 is rendered for the first time
  useEffect(() => {
    // debugger
    if (isInitialMount.current) {
      // This block will only run on the first render
      isInitialMount.current = false;
      if (llocation.state && llocation.state.id) {
        setSpinner(true);
        dispatch(getclosetInfo({ id: llocation.state.id, p: pageNumber || 0 }));
        window.scrollTo(0, 0);

        const token = localStorage.getItem("clttoken");
        if (token) {
          setBannerSpinner(true);
          dispatch(getuserBannerInfo({ id: llocation.state.id, token }));
        }
      } else if (searchParams.get("closet")) {
        dispatch(
          getclosetInfo({ id: searchParams.get("closet"), p: pageNumber || 0 })
        );
      } else {
        navigate("/404");
      }
    } else {
      // This runs on subsequent renders but skips when pageNumber is 0 on first page load
      if (pageNumber === 0 && isFirstPageZeroRender.current) {
        // Prevent first render when pageNumber is 0
        isFirstPageZeroRender.current = false;
        return;
      }

      if (pageNumber !== undefined) {
        setSpinner(true);
        dispatch(
          getclosetInfo({
            id: llocation.state.id || searchParams.get("closet"),
            p: pageNumber || 0,
          })
        );
        window.scrollTo(0, 175);
      }
    }
  }, [pageNumber]);

  let lastDeletedId;

  const deletekey = (id) => {
    lastDeletedId = id;
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this product!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        console.log(`Please Delete it. ${id}`);
        let token = localStorage.getItem("clttoken");
        setSpinner(true);
        dispatch(deleteItem({ token, id }));
      }
    });
  };

  console.log("User Info ", userInfo);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="This page is for viewing the closet and its products to the closestcloset website"
        />
        <meta property="og:type" content="product" />
        <meta property="og:title" content={`Closest Closet - How It Works`} />
        <meta
          property="og:description"
          content={
            "This page is for viewing the closet and its products to the closestcloset website"
          }
        />
        <meta
          property="og:url"
          content={`https://www.closestcloset.com/${window.location.pathname}`}
        />
        <meta
          property="og:image"
          content={
            "https://res.cloudinary.com/closet-closest/image/upload/v1703952666/Untitled_design_xekf33.png"
          }
        />
        <meta property="og:site_name" content="ClosestCloset" />
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Closest Closet - How It Works`} />
        <meta
          name="twitter:description"
          content={
            "This page is for viewing the closet and its products to the closestcloset website"
          }
        />
        <meta
          name="twitter:image"
          content={
            "https://res.cloudinary.com/closet-closest/image/upload/v1703952666/Untitled_design_xekf33.png"
          }
        />
      </Helmet>
      <div className="page-container" style={{ position: "relative" }}>
        {/* {spinner ? <Spinner /> : ""} */}

        {/* <ToastContainer
        position="bottom-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}

        <div className="" style={{ marginTop: "2rem" }}>
          {userInfo && bannerInfo && (
            <MyClosetBanner propBannerInfo={bannerInfo} />
          )}
          {userInfo && bannerInfo && <br />}
          {spinner ? (
            <>
          {/* //  <div style={{position: "relative", width: "95%", margin: "auto",marginBottom: "1.25rem", height: "70vh"}} > */}
            {/* <Spinner /> */}
            <ShimmerCloset />
            </>
            
          ) : (
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
              <OtherClosetItem
                othersCloset={true}
                userInfo={closetStateInfo}
                deletekey={deletekey}
                pageNumber={pageNumber}
                productCount={closetProductsCount}
              />
              
              {closetProductsCount > 20 && (
                <Pagination
                  noMarginLeft={true}
                  noMarginTop={true}
                  pageCount={closetProductsCount / 20}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                />
              )}
            
            </ScrollAnimation>
          )}
        </div>
      </div>
    </>
  );
};

export default Closet;
