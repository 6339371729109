import React, { useEffect, useState } from 'react'
import Spinner from '../components/Spinner/Spinner';

const AcceptablePolicy = () => {
    const [Spinner1, setSpinner1] = useState(false)

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://app.termly.io/embed-policy.min.js";
        script.async = true;
        document.body.appendChild(script);
      }, []); 

  return (
    <div
    className="page-container"
      name="termly-embed"
      data-id="a80f4b3c-a442-49b1-9097-245350b59dcf"
      data-type="iframe"
      style={{
        marginBottom:"20px"
      }}
    ></div>
  )
}

export default AcceptablePolicy