import React, { useEffect, useState } from 'react'
import Spinner from '../components/Spinner/Spinner';

const PrivacyPolicyTermly = () => {
    const [Spinner1, setSpinner1] = useState(false)

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://app.termly.io/embed-policy.min.js";
        script.async = true;
        document.body.appendChild(script);
      }, []); 

  return (
    <div
    className="page-container"
      name="termly-embed"
      data-id="25e63ca2-2ce4-4ec2-9ac9-e8d2b6a74cc1"
      data-type="iframe"
      style={{
        marginBottom:"20px"
      }}
    ></div>
  )
}

export default PrivacyPolicyTermly