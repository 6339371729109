import React, { useEffect, useState } from 'react'
import Spinner from '../components/Spinner/Spinner';

const TermsConditionTermly = () => {
    const [Spinner1, setSpinner1] = useState(false)

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://app.termly.io/embed-policy.min.js";
        script.async = true;
        document.body.appendChild(script);
      }, []); 

  return (
    <div
      className="page-container"
      name="termly-embed"
      data-id="abb33b66-1f02-4e69-929d-b5b1685cd719"
      data-type="iframe"
      style={{
        marginBottom:"20px"
      }}
    ></div>
  )
}

export default TermsConditionTermly