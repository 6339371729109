import React, { useEffect, useState } from 'react'
import Spinner from '../components/Spinner/Spinner';

const DisclaimerPage = () => {
    const [Spinner1, setSpinner1] = useState(false)

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://app.termly.io/embed-policy.min.js";
        script.async = true;
        document.body.appendChild(script);
      }, []); 

  return (
    <div
    className="page-container"
      name="termly-embed"
      data-id="90d08b6b-de30-4d44-8e62-ad1ecf53e70d"
      data-type="iframe"
      style={{
        marginBottom:"20px"
      }}
    ></div>
  )
}

export default DisclaimerPage